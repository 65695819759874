:root {
  --primary-color: #dbe1c7;
  --secondary-color: #fabf28;
  --third-color: #163a45;
  --fourth-color: #1d0f2d;
  --swiper-theme-color: #163a45 !important;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

*,
html,
body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Neueworld Condensed", helvetica, arial, sans-serif;
}

body {
  overflow-x: hidden;
  font-family: Helvetica, sans-serif;
  background-color: var(--primary-color) !important;
  font-size: 1em;
  line-height: 1.4em;
}

@media screen and (min-width: 20em) {
  body {
    font-size: calc(1em + (1.3125 - 1) * ((100vw - 20em) / (80 - 20)));
    line-height: calc(1.4em + (1.8 - 1.4) * ((100vw - 20em) / (80 - 20)));
  }
}

@media (min-width: 80em) {
  body {
    font-size: 1.3125em;
    line-height: 1.8em;
  }
}

@media (max-width: 1000px) {
  body {
    font-size: 16px;
    line-height: 1.4em;
  }
}

h2.section-title {
  font-family: "Alfa Slab One", cursive;
  font-style: normal;
  font-weight: 400;
  font-size: 147px;
  line-height: 209px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.945px;
  text-transform: uppercase;
  /* #ADB595 */
  color: #adb595;
}

@media (max-width: 800px) {
  h2.section-title {
    font-size: 13.5vw;
    line-height: 99px;
  }
}

@media (max-width: 450px) {
  h2.section-title {
    font-size: 67px;
    line-height: 99px;
  }
}

a {
  color: unset !important;
  text-decoration: none !important;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}

/* BUTTONS */

.button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.button-container > * {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 350px;
}

.primary-button {
  padding: 22.1406px 46.125px 23.2812px 44.4688px;
  background: var(--secondary-color);
  border: 2px solid var(--secondary-color);
  border-radius: 12px;
  text-transform: uppercase;
  margin-right: 10px;
  color: var(--third-color);
  font-weight: 700;
  width: 100%;
}

.secondary-button {
  font-weight: 700;
  padding: 22.1406px 45.9844px 23.2812px 44.4688px;
  border: 2px solid #dbe1c7;
  border-radius: 12px;
  text-transform: uppercase;
  background-color: transparent;
  color: var(--primary-color);
  width: 100%;
}

.third-button {
  width: 100%;
  text-transform: uppercase;
  color: #fff;
  background: var(--third-color);
  border-radius: 10px;
  border: 2px solid var(--third-color);
  cursor: pointer;
  padding: 22.1406px 45.9844px 23.2812px 44.4688px;
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 13.3333px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
}

/* HERO */

.hero {
  height: 100vh !important;
  width: 100% !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center center !important;
}

.hero-overlay {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.66) 0%,
    rgba(0, 0, 0, 0.16) 100%
  );
  pointer-events: none;
}

/* NAVABAR */

.navbar {
  width: 100%;
  height: 75px;
  color: #dbe1c7;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 50px;
}

.navbar .logo {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 15.12px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1512px;
  text-transform: uppercase;
  color: var(--primary-color);
  z-index: 11;
}
.navbar .logo.fixed {
  position: fixed;
  top: 29px;
  left: 20px;
}

.navbar .links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5.65625px 56.6875px 0px 0px;
  gap: 56.69px;
}
.navbar .links a {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 15.12px;
  line-height: 17px;
  text-transform: uppercase;
}

.navbar .social,
footer .social,
.menu .social {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 9.06px;
}

footer .social .social-icon,
.navbar .social .social-icon,
.menu .social .social-icon {
  width: 41.58px;
  height: 41.58px;
  background: var(--primary-color);
  border-radius: 3.78px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--fourth-color) !important;
}

.navbar .hamburger-menu {
  display: none;
}

/* DROPDOWN NAVBAR */

/* Style The Dropdown Button */
.dropbtn {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--primary-color);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 10px;
}

/* Links inside the dropdown */
.dropdown-content a {
  border-radius: 10px;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: var(--secondary-color);
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
/* .dropdown:hover .dropbtn {
  background-color: #3e8e41;
} */

.three {
  width: 23.8%;
}

.row .three {
  padding: 80px 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #2c3e50;
  color: #ecf0f1;
  text-align: center;
}

.hamburger .line {
  width: 50px;
  height: 5px;
  background-color: var(--primary-color);
  display: block;
  margin: 8px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.hamburger:hover {
  cursor: pointer;
}

#hamburger-9 {
  position: relative;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#hamburger-9.is-active {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#hamburger-9:before {
  content: "";
  position: absolute;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 70px;
  height: 70px;
  border: 5px solid transparent;
  top: calc(50% - 35px);
  left: calc(50% - 35px);
  border-radius: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#hamburger-9.is-active:before {
  border: 5px solid var(--primary-color);
}

#hamburger-9.is-active .line {
  width: 35px;
}

#hamburger-9.is-active .line:nth-child(2) {
  opacity: 0;
}

#hamburger-9.is-active .line:nth-child(1) {
  -webkit-transform: translateY(13px);
  -ms-transform: translateY(13px);
  -o-transform: translateY(13px);
  transform: translateY(13px);
}

#hamburger-9.is-active .line:nth-child(3) {
  -webkit-transform: translateY(-13px) rotate(90deg);
  -ms-transform: translateY(-13px) rotate(90deg);
  -o-transform: translateY(-13px) rotate(90deg);
  transform: translateY(-13px) rotate(90deg);
}

@media screen and (max-width: 1050px) {
  .navbar .links,
  .navbar .social {
    display: none;
  }
  .navbar {
    justify-content: space-between;
    padding: 0 20px;
  }
  .navbar .hamburger-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    scale: 0.7;
  }
}

.menu {
  height: 100%;
  width: 100vw;
  background-color: var(--third-color);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 10;
  overflow-y: hidden !important;
  transition: all 0.4s ease-out;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.hamburger-menu.fixed {
  position: fixed;
  top: 13px;
  left: unset;
  right: 20px;
}
.menu .links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: var(--primary-color);
  font-size: 2em;
  line-height: 1.4em;
}
.hamburger-menu {
  transition: all 1s ease-in-out;
  z-index: 11;
}

@media screen and (min-width: 1000px) {
  .menu {
    display: none;
  }
}

/* NAVIGATION BAR */
.NavigationBarContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navigation-bar {
  position: fixed;
  margin: 0 auto;
  /* background-color: var(--third-color); */
  bottom: 20px;
  width: 85vw;
  max-width: 500px;
  z-index: 999;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  backdrop-filter: blur(15px);
  background: rgba(0, 0, 0, 0.3);
  /* font-size: 3vw; */
  padding: 0 10px;
}

.navigation-bar {
  font-size: 16px;
}
@media screen and (min-width: 320px) {
  .navigation-bar {
    font-size: calc(16px + 6 * ((100vw - 320px) / 680));
  }
}
@media screen and (min-width: 1000px) {
  .navigation-bar {
    font-size: 22px;
  }
}

.navigation-bar-item {
  /* color: var(--primary-color); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  outline: none;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
}

/* .navigation-bar-item svg {
  margin-bottom: 10px;
} */

/* HEROTITLE */

.heroTitle {
  height: calc(100% - 75px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 50px;
}
.heroTitle h1 {
  font-family: helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 65.5px;
  line-height: 94px;
  margin-top: -50px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: var(--primary-color);
}
.heroTitle .button-container {
  justify-content: flex-start !important;
}

@media screen and (max-width: 1000px) {
  .heroTitle {
    align-items: center;
    text-align: center;
    padding: 0 18px;
  }
  .heroTitle h1 {
    font-size: 3.5em;
    line-height: 1.4;
  }
  .button-container {
    flex-direction: column;
    margin-top: 15px;
  }
  .button-container > * {
    margin: 5px 0;
    max-width: 400px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 365px) {
  .heroTitle h1 {
    font-size: 3em;
    line-height: 1.4;
  }
}
@media screen and (max-width: 320px) {
  .heroTitle h1 {
    font-size: 2em;
    line-height: 1.4;
  }
}

/* REVIEWS */

.reviewsContenitor {
  /* max-width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center; */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 135px 6vw;
  gap: 35.59px;
  justify-content: center;
}

.reviewsContenitor .review {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #faffe9;
  box-shadow: 7px 10px 12px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  padding: 40px 20px;
  min-height: 240px;
}
.reviewsContenitor .review .review-text {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  /* font-size: 20.79px; */
  line-height: 28px;
  color: var(--forth-color);
  /* min-height: 130px; */
  text-align: center;
  background: url("./assets/review-quotes.png");
  background-repeat: no-repeat;
}
.reviewsContenitor .review .review-writer {
  margin-top: 1.125em;
}
.reviewsContenitor .review .review-img {
  width: 100px;
  height: 55px;
  margin: 10px;
}
.reviewsContenitor .review .review-img.blame {
  width: 150px;
  height: auto;
  margin: 10px;
}

@media screen and (max-width: 1000px) {
  .reviewsContenitor {
    /* max-width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center; */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 50px 50px;
    /* gap: 75.59px; */
  }
  .reviewsContenitor .review {
    display: flex;
    width: 100%;
  }
  /* .reviewsContenitor .review .review-text {
    min-height: 75px;
  } */
}
@media screen and (max-width: 450px) {
  .reviewsContenitor {
    padding: 50px 10px;
  }
}

/* ABOUT */

/* .aboutContainer {
  display: flex;
  justify-content: center;
  margin: 130px 6vw;
  margin-top: 0 !important;
} */
.aboutContainer .description {
  min-height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 75px 75px;
  border-radius: 12px;
}

.aboutContainer .description h2 {
  margin: 10px 0;
  justify-content: flex-start;

  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 29px;
  /* or 103% */

  display: flex;
  align-items: center;
  letter-spacing: -0.945px;
  text-transform: uppercase;

  /* #163A45 */

  color: #163a45;
}
.aboutContainer .description p {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 18.9px;
  display: flex;
  align-items: center;
  color: #163a45;
  margin: 30px 0;
}

.aboutContainer .about-img {
  width: 50%;
  object-fit: cover;
  border-radius: 12px;
}

.aboutContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 130px 6vw;
  margin-top: 0;
}
.aboutContainer .description {
  min-height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 75px 75px;
  border-radius: 12px;
}
.aboutContainer .description h2 {
  margin: 10px 0;
  justify-content: flex-start;

  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 29px;
  /* or 103% */

  display: flex;
  align-items: center;
  letter-spacing: -0.945px;
  text-transform: uppercase;

  /* #163A45 */

  color: #163a45;
}
.aboutContainer .description p {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 18.9px;
  display: flex;
  align-items: center;
  color: #163a45;
  margin: 30px 0;
}

.aboutContainer .about-img {
  width: 50%;
  object-fit: cover;
  border-radius: 12px;
}

.aboutContainer .about-swiper .about-img {
  width: 100% !important;
  max-height: 80vh;
}

.aboutContainer .about-swiper .about-img iframe {
  max-height: none;
  min-height: 100%;
}

.about-swiper {
  padding: 5vw !important;
  width: 50% !important;
  padding-right: 100px;
}
.about-swiper .swiper-slide {
  border-radius: 12px;
}

@media (max-width: 1250px) {
  .aboutContainer {
    margin: 50px 4vw;
    margin-top: 0 !important;
  }
  .aboutContainer .description {
    padding: 75px 50px;
  }
}

@media screen and (max-width: 850px) {
  .aboutContainer {
    flex-direction: column;
    justify-content: center;
  }
  .aboutContainer.reverse {
    flex-direction: column-reverse;
  }
  .aboutContainer .description {
    width: 100%;
    padding: 25px;
  }
  .aboutContainer .description p {
    margin: 10px 0;
  }
  .aboutContainer .description .button-container {
    margin-top: 0;
  }
  .aboutContainer .about-img {
    width: 100%;
  }
  .about-swiper {
    width: 100% !important;
    padding-right: 0 20px;
  }
}

body {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
body::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

/* SERVICE */

.serviceContainer {
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
  margin: 130px 6vw;
  margin-top: 20px !important;
}
.serviceContainer .description {
  min-height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 75px 75px;
  border-radius: 12px;
}
.serviceContainer .description h2 {
  margin: 10px 0;
  justify-content: flex-start;

  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 29px;
  /* or 103% */

  display: flex;
  align-items: center;
  letter-spacing: -0.945px;
  text-transform: uppercase;

  /* #163A45 */

  color: #163a45;
}
.serviceContainer .description p {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 18.9px;
  display: flex;
  align-items: center;
  color: #163a45;
  margin: 30px 0;
}

.serviceContainer .about-img {
  width: 50%;
  object-fit: cover;
  border-radius: 12px;
}

@media (max-width: 1250px) {
  .serviceContainer {
    margin: 130px 1vw;
  }
  .serviceContainer .description {
    padding: 75px 50px;
  }
}

@media screen and (max-width: 850px) {
  .serviceContainer {
    flex-direction: column;
    justify-content: center;
  }
  .serviceContainer.reverse {
    flex-direction: column-reverse;
  }
  .serviceContainer .description {
    width: 100%;
    padding: 10px;
    padding: 25px;
  }
  .serviceContainer .description p {
    margin: 10px 0;
  }
  .serviceContainer .description .button-container {
    margin-top: 0;
  }
  .serviceContainer .about-img {
    width: 100%;
  }
}

@media (max-width: 450px) {
  .serviceContainer {
    margin: 50px 1vw;
  }
}
/* MUSIC VIDEO */

.musicVideo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 130px;
}

.dj-iframe {
  min-height: 100%;
  border-radius: 12px;
}

@media (max-width: 1250px) {
  .musicVideo {
    margin-bottom: 50px;
  }
}
.musicVideo .frame-container {
  width: 100%;
}

.musicVideo iframe {
  width: 100%;
  height: 50vh;
  border-radius: 12px !important;
  overflow: hidden !important;
  min-height: 361px;
}

@media screen and (max-width: 850px) {
  .musicVideo {
    margin-top: 18px;
  }
}
@media screen and (min-width: 1500px) {
  .musicVideo iframe {
    height: 65vh;
  }
}

.dj {
  margin-bottom: 130px;
}

@media (max-width: 767px) {
  .dj {
    margin-bottom: 50px;
  }
  .dj-iframe {
    min-height: 50vh;
  }
}

/* SWIPER */

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: contain;
  background-position: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* musicVideoSwiper */
.musicVideoSwiper {
  display: flex;
  justify-content: center !important;
  padding: 50px 0 !important;
}

@media screen and (max-width: 850px) {
  .musicVideoSwiper {
    padding: 18px 0 !important;
  }
}
.musicVideoSwiper .swiper-slide {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  /* min-width: 270px; */
}

.musicVideoSwiper .swiper-slide .thumnailImg {
  width: 100%;
  height: 25vh !important;
  border-radius: 12px;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center center !important;
}

.musicVideoSwiper .swiper-slide span {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 15.12px;
  line-height: 17px;
  display: flex;
  align-self: flex-start;
  letter-spacing: -0.945px;
  margin-top: 5px;
  color: #1d0f2d;
}
.musicVideoSwiper .swiper-slide h3 {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 18.9px;
  line-height: 25px;
  display: flex;
  align-self: flex-start;
  letter-spacing: -1.18125px;
  text-transform: uppercase;
  /* margin-top: 10px; */
  text-align: left;
}
.musicVideoSwiper .swiper-slide .slide-text {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* padding: 20px; */
  align-items: flex-end;
}

.musicVideoSwiper .swiper-slide .slide-text .author-name-contenitor {
  display: flex;
}

.main-contenitor {
  padding: 0 50px;
}
@media screen and (max-width: 850px) {
  .main-contenitor {
    padding: 0 18px;
  }
}

.slide-description h3,
.slide-description .author-name-contenitor span a {
  color: var(--primary-color) !important;
}

.services-provided {
  display: flex;
  flex-direction: row;
  margin: 5px 10px;
}
.services-provided > * {
  margin: 0 2.5px;
}

.mastering-icon {
  background-color: #b295f1;
  color: white !important;
  border-radius: 10px;
  padding: 3px 5px;
  font-size: 14px !important;
}
.mixing-icon {
  background-color: #ff0abb;
  color: white !important;
  border-radius: 10px;
  padding: 3px 5px;
  font-size: 14px !important;
}
.producing-icon {
  background-color: #f87371;
  color: white !important;
  border-radius: 10px;
  padding: 3px 5px;
  font-size: 14px !important;
}

.opac-background {
  background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.66));
  /* background-color: rgba(0, 0, 0, 0.66); */
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: space-between;
  padding: 20px;
  border-radius: 12px;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: -5px !important;
}

/* CONTACT */
/* 
.contactContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px 0;
} */
@media (max-width: 767px) {
  #contact {
    padding: 0 3vw;
  }
}

.formContainer img {
  display: none;
}
.formContainer .description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contactFormContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contactFormContainer h2 {
  text-align: center;
  margin-bottom: 20px;
}
.contactFormContainer .contact-img {
  width: 50%;
  /* max-height: 500px; */
}
.contactFormContainer form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 600px;
}

.formControl > * {
  margin: 0.5rem 0 !important;
}

.formContainer > * {
  width: 100%;
}

.formContainer form {
  width: 100%;
}

input,
select,
textarea {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  border: none;
  margin: 0.5rem 0;
  font-size: 1.1rem;
  padding: 0 10px;
}
textarea {
  min-width: 100%;
  height: unset;
  resize: none;
  padding: 10px 10px;
  font-family: Helvetica, sans-serif;
}
textarea::placeholder {
  /* padding: 10px 0px; */
  font-size: 1.1rem;
  height: unset;
  font-family: Helvetica, sans-serif;
}
.input-label {
  position: relative;
}

@media screen and (min-width: 700px) {
  .contactFormContainer {
    flex-direction: row;
  }
  .formContainer img {
    display: block;
  }
  /* .contactFormContainer form {
    width: 50%;
  } */
}

/* input:-internal-autofill-selected {
  background-color: var(--secondary-color) !important;
} */

.formContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  margin-bottom: 10vh;
}
.contact-information {
  background-color: var(--third-color);
  padding: 5vh 5vw;
  margin-right: 30px;
  color: white;
  border-radius: 10px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}

.contact-information h2 {
  text-transform: capitalize;
}

.contact-information-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.contact-information-details > * {
  margin: 10px;
}

.contact-information-social {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media (max-width: 767px) {
  .formContainer {
    flex-direction: column;
  }
  .contact-information {
    margin-right: 0px;
    margin-bottom: 20px;
  }
}
/* FOOTER */

.footer {
  background: url("https://i.scdn.co/image/ab6761670000ecd4d1634b187af32f1b9f945370");
  color: white;
  padding: 50px;
  background-repeat: no-repeat;
  background-size: cover;
  backdrop-filter: blur(10px);
  /* margin-top: 50px; */
  position: relative;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  z-index: -1;
  filter: blur(10px);
  -moz-filter: blur(10px);
  -webkit-filter: blur(10px);
  -o-filter: blur(10px);

  transition: all 2s linear;
  -moz-transition: all 2s linear;
  -webkit-transition: all 2s linear;
  -o-transition: all 2s linear;
}

.footer .email {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.footer .email h3 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: "Neueworld Condensed", sans-serif;
  font-size: 3.25em;
  line-height: 1;
  text-transform: uppercase;
}
.footer .email p {
  font-family: Helvetica, sans-serif;
  color: white;
  font-size: 3vw;
  line-height: 1;
  font-weight: 400;
  padding-top: 20px;
}

.footer .email form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.footer .email .form-container {
  margin: 15px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.footer .email .form-container input {
  border-radius: 12px;
  text-indent: 10px;
  width: 100%;
  height: 65px;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 2px solid black;
  max-width: 350px;
}
.footer .email .form-container button {
  width: 100%;
  height: 65px;
  max-width: 40%;
  padding: unset;
  max-width: 350px;
}
@media screen and (min-width: 1050px) {
  .footer .email {
    flex-direction: row;
    justify-content: space-between;
    text-align: left !important;
  }
  .footer .email .form-container {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  .footer .email h3 {
    width: 50%;
  }
  .footer .email form {
    width: 45%;
  }
  .footer .email .form-container input {
    width: 60%;
    margin-right: 10px;
  }
  .footer .email .form-container button {
    width: 40%;
  }
  .footer .email form p {
    font-size: 1em !important;
    align-self: flex-start;
  }
  .footer .email span {
    font-size: 0.7em !important;
    align-self: flex-start;
  }
}

footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  color: var(--primary-color);
  background-color: var(--third-color);
  padding-bottom: 125px;
}

footer .logo {
  margin-bottom: 20px;
}

/* Logo */

.LogoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100px;
  position: fixed;
  z-index: 1000;
}

.LogoContainer img {
  max-height: 100px;
}

/* Text Video Background */

.video-container {
  width: 100%;
  height: 100vh;
  position: relative;
}
.video-container video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.video-container img::after {
  content: "";
  width: 100vh;
  height: 100vw;
  background-color: #1d0f2d;
}

.text-box {
  position: absolute;
  left: 0;
  width: 100%;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 70px;
  font-weight: 900;
  mix-blend-mode: unset !important;
  text-align: center;
  padding: 0em;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  /* top: -1px; */
  bottom: 0px;
}

.opac-background-hero {
  width: auto;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  padding: 2.5rem;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
}

.text-box .social {
  display: flex;
}

.text-box .social .social-icon svg {
  padding: 20px;
}

.text-box span {
  /* font-size: 6.2rem; */
  /* padding: 0.4em; */
  margin: 0;
  padding: 0;
  /* font-family: "Yanone Kaffeesatz", sans-serif; */
  /* font-family: "Oswald" !important; */
  line-height: 1;
}

.text-box .carmine {
  font-size: 4.2rem;
  /* margin-bottom: -2rem; */
}
.text-box .sembra {
  font-size: 1.4rem;
  font-weight: 400;
  margin-top: 2vh;
}
.text-box .brooklyn {
  font-size: 5.2rem;
  margin-top: 5vh;
}

.text-box img {
  border-radius: 10px;
}

@media (max-width: 550px) {
  .text-box .sembra {
    font-size: 1.2rem;
    /* margin-bottom: -2.5rem; */
  }
  .text-box .brooklyn {
    font-size: 3.2rem;
  }
  .opac-background-hero {
    padding: 2rem 1rem 0rem 1rem;
  }
}

@media (max-width: 375px) {
  .opac-background-hero {
    padding: 2rem 1rem 0rem 1rem;
  }
  .text-box .brooklyn {
    font-size: 2rem;
  }
  .text-box img {
    width: 60vw;
  }
}

/* Link margin from top */
:target {
  scroll-margin-top: 75px;
}

/* SCROLL */

html {
  font-size: 16px;
  scroll-behavior: smooth;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  margin: 0;
}

.header {
  position: fixed;
  width: 100%;
  padding: 1em;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0 0 0.5em 0 rgba(0, 0, 0, 0.15);
}

.menu {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

.menu-item:not(:last-child) {
  margin-right: 1.5em;
}

.menu-item {
  font-weight: 500 !important;
  color: var(--primary-color) !important;
  text-decoration: none !important;
  transition: color 0.1s ease !important;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}

.menu-item:hover,
.menu-item-active {
  color: var(--secondary-color) !important;
}

/* .section {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  color: #353535;
} */

/* SERVICES */

.production-tools {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding-bottom: 40px;
}
.production-tools img {
  max-width: 50px;
  aspect-ratio: 1;
}

/* SKELETON */

.skeleton {
  opacity: 0.7;
  animation: skeleton-loading 1s linear infinite alternate;
}

.skeleton-text {
  width: 100%;
  height: 0.5rem;
  margin-bottom: 0.25rem;
  border-radius: 0.125rem;
}

.skeleton-text:last-child {
  margin-bottom: 0;
  width: 80%;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
